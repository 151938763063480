<template>
  <div class="flex items-start">
    <div class="flex items-center h-5">
      <input
        type="checkbox"
        class="h-4 w-4 text-blue-600 border-blue-gray-300 rounded focus:ring-blue-500 cursor-pointer"
        :class="{ 'text-blue-gray-400 cursor-not-allowed' : $attrs.disabled }"
        :checked="checked"
        :name="name"
        v-bind="$attrs"
        @input="(event) => updateInput(event.target as HTMLInputElement)"
      >
    </div>
    <div
      v-if="label"
      class="ml-3 text-sm leading-5"
    >
      <label
        :for="name"
        class="font-medium text-blue-gray-700"
        :class="{ 'text-blue-gray-400 cursor-not-allowed' : $attrs.disabled }"
      >
        {{ label }}
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['update:checked']);

defineProps<{
  name: string,
  checked: boolean,
  label?: string,
}>();

const updateInput = (target: HTMLInputElement) => {
  emit('update:checked', target?.checked);
};
</script>
